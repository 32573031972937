import React, { Component } from 'react'
import { Link } from 'gatsby'

class OtherLinks extends Component {

    renderLinks = (links) => {
        return links.map((link, index) => {
            return (
                <li><a target="_blank" rel="noopener noreferrer" key={"link_" + index} href={link.link}>{link.name}</a> - {link.desc}</li>
            )
        })
    }

    render() {
        return (
            <div className="post">
                <h3>Weitere Links</h3>
                <ul className="productLinks">
                    {this.renderLinks(this.props.links)}
                    <li><Link to="/downloads">Downloads</Link> - Diverse Dokumente zum Thema Abacus HR, Zeit und Lohn</li>
                </ul>
            </div>
        )
    }
}

export default OtherLinks;