import React from 'react'
import ReactPlayer from 'react-player'
import Layout from '../../components/layout'
import OtherLinks from '../../components/otherLinks/otherLinks'

import p1 from '../../images/pages/time/Abacus Zeiterfassung.png'
import p2 from '../../images/pages/time/Abacus Zeiterfassung Timeline.png'

const links = [
    {
        name: 'Abacus AbaClik',
        desc: 'Abacus AbaClik (Handy App)',
        link: 'https://www.abaclik.ch/de/'
    },
    {
        name: 'Abacus AbaClock Terminal',
        desc: 'Abacus AbaClock Terminal',
        link: 'https://www.abaclock.ch/'
    },
    {
        name: 'Abacus Abapoint',
        desc: 'Abacus Abapoint',
        link: 'https://www.abapoint.ch/'
    },
    {
        name: 'Abacus Zeiterfassung',
        desc: 'Abacus Zeiterfassung',
        link: 'https://hr-portal.ch/zeiterfassung/'
    }
]

const abacusHR = () => (
    <Layout>
        <section id="abaTime" className="section-wrapper">
            <header className="section-header">
                <h3>Abacus Zeiterfassung</h3>
            </header>
            <div className="post" id="overview">
                <p>
                    Seit dem 1. Januar 2016 sind Unternehmen dazu verpflichtet, die geleisteten Arbeitsstunden der Mitarbeitenden zu dokumentieren.
                    Mit der mobilen App AbaCliK, den stationären AbaClocK Terminals oder AbaPoint Beacons und den Erfassungsmöglichkeiten am Browser
                    über das MyAbacus Mitarbeiter-Portal kann die Erfassung bequem, jederzeit und überall erfolgen. Dank der Abacus Zeiterfassungs-Plattform
                    haben Arbeitnehmer und Arbeitgeber immer und überall einen Überblick über die Arbeitszeiten, Gleit- und Überzeiten sowie die Absenzen.
                </p>
                <br />
                <p>
                    Zusätzlich bietet Abacus die Möglichkeit, mit AbaProject eine Projekt- und Leistungserfassung mit integrierter Fakturierung zu nutzen.
                    Eine rasche Fakturierung der erfassten Projektleistungen und ein umfassendes Controlling sind somit sichergestellt.
                </p>
                <br />
                <img src={p1} alt="Abacus Zeiterfassung" width="100%" />
            </div>

            <div className="post" id="highlights">
                <ul>
                    <li>Zeiterfassung jederzeit und überall (Mobile App, Terminal und HR Portal stehen zur Verfügung)</li>
                    <li>Keine Mehrfacherfassungen</li>
                    <li>Daten in Echtzeit verfügbar</li>
                    <li>Kontrolle der aktuellen Arbeitszeiten durch Vorgesetzte</li>
                    <li>Abbildung von Überstunden und Überzeiten gemäss SECO-Richtlinien</li>
                    <li>Feiertags- und Wochenendarbeit automatisch abrechnen</li>
                    <li>Automatisierte, gesetzeskonforme Pausenkorrekturen</li>
                    <li>Einsparung von administrativer Arbeit, Zeit und Kosten</li>
                    <li>Positive Auswirkungen auf die Motivation der Mitarbeitenden</li>
                    <li>Daten und Auswertungen in der Abacus Business Software verfügbar</li>
                </ul>
                <br />
                <img src={p2} alt="Abacus Zeiterfassung Timeline" width="100%" />
            </div>

            <div className="post section-with-vid" id="video">
                <div className="vid">
                    <ReactPlayer url="https://www.youtube.com/watch?v=MmQeA7d6aDw" controls={true} playing height="100%" width="100%" />
                </div>
            </div>

            <OtherLinks links={links} />

        </section>
    </Layout>
)


export default abacusHR
